<template>
  <div
    v-if="mytxns.length > 0"
    class="
      q-mt-lg
      q-pa-md q-gutter-md
      fit
      row
      wrap
      justify-center
      items-start
      content-start
    "
  >
    <q-card v-for="k in mytxns" :key="k.id" class="my-card">
      <q-card-section v-if="k.nfts" horizontal>
        <q-img
          class="my-nft-img q-ma-md mobile-hide"
          ratio="1"
          :src="k.nfts.data.image"
        />

        <q-card-section>
          <p class="text-h6">
            {{ k.nfts.data.name }}
            <span v-if="k.status == 'DONE'" class="text-blue">
              #{{ k.tokenid }} of {{ k.nfts.data.count }}
            </span>
          </p>
          <p v-if="k.txn" class="text-body>">
            <span v-if="k.txn.data">
              <a
                v-if="k.txn.data.transactionHash"
                :href="chainscan_url + 'tx/' + k.txn.data.transactionHash"
              >
                Txn:
                {{
                  k.txn.data.transactionHash.substring(0, 15) +
                  "..." +
                  k.txn.data.transactionHash.substring(50)
                }}
              </a>
            </span>

            <a
              v-if="k.txn.transactionHash"
              :href="chainscan_url + 'tx/' + k.txn.transactionHash"
            >
              Txn:
              {{
                k.txn.transactionHash.substring(0, 15) +
                "..." +
                k.txn.transactionHash.substring(50)
              }}
            </a>
          </p>
          <p class="text-body">
            <a :href="chainscan_url + 'address/' + k.contract">
              Contract:
              {{
                k.contract.substring(0, 15) + "..." + k.contract.substring(35)
              }}
            </a>
          </p>
          <p class="text-body">TID:               
              {{ k.tid.length > 15 ? 
                k.tid.substring(0, 15) +
                "..." +
                k.tid.substring(60) : k.tid
              }}</p>
          <p class="text-body">
            {{ $t("purchase_date") }}: {{ pdate(k.created_at) }}
          </p>
          <p class="text-body">{{ $t("purchase_price") }}: ${{ k.price }}</p>
          <p class="text-body">
            {{ $t("order_id") }}: {{ k.id }} From: {{ k.ufrom.nickname }}
          </p>
          <p class="text-body">{{ $t("status") }}: {{ k.status }}</p>
          <q-btn color="blue" :to="'/nft/' + k.contract">
            {{ $t("view") }}</q-btn
          >
          <q-btn
            class="q-ml-md"
            v-if="k.status == 'PENDING'"
            color="orange"
            :to="'/checkout/' + k.id"
          >
            Pay
          </q-btn>
        </q-card-section>
      </q-card-section>
    </q-card>
  </div>
  <div
    v-else
    class="
      q-pa-md q-gutter-md
      fit
      row
      wrap
      justify-center
      items-start
      content-start
    "
  >
    <q-banner rounded class="bg-orange text-white">
      You don't have any orders.
    </q-banner>
  </div>
</template>
<style lang="sass" scoped>
.my-card
  width: 100%
  max-width: 800px

.my-nft-img
  min-width: 250px
  width: 250px
  height: 250px
</style>

<script>
import { ref, onBeforeMount } from "vue";
import { supabase as s } from "../supabase";
import { useQuasar } from "quasar";
import { chainscan } from "../web3.js";
export default {
  name: "MyTXNs",
  setup() {
    const user = s.auth.user();
    console.log(user);
    const mytxns = ref([]);
    const chainscan_url = ref(chainscan);

    onBeforeMount(async () => {
      let { data: txns, error } = await s
        .from("txns")
        .select("*,nfts(data),ufrom:ufrom(*),uto:uto(*)")
        // .eq("uto", user.id)
        .eq("to", user.user_metadata.wallet)
        .order("created_at", { ascending: false });
      if (error) console.log(error);
      console.log(txns);
      mytxns.value = txns;
    });
    return {
      mytxns,
      chainscan_url,
    };
  },
  methods: {
    show_nft(id) {
      this.$router.push("/nft/" + id);
    },
    pdate(dstr) {
      const date = new Date(dstr);
      return new Intl.DateTimeFormat("default", { dateStyle: "long" }).format(
        date
      );
    },
  },
};
</script>